import { combineReducers } from "redux";
import reservationsReducer from "../features/booking-calendars/redux/reducer";
import bookingInstrumentsReducer from "../features/booking-instruments/redux/reducer";
import usersReducer from "../features/booking-users/redux/reducer";
import userReducer from "../features/user/redux/reducer";
import bookingAdminReducer from "../features/booking-admin/redux/reducer";
import actionTypesReducer from "../gql/redux/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  bookingInstruments: bookingInstrumentsReducer,
  reservations: reservationsReducer,
  users: usersReducer,
  bookingAdmin: bookingAdminReducer,
  actionTypes: actionTypesReducer,
});

export default rootReducer;

export type RootStore = {
  user: ReturnType<typeof userReducer>;
  bookingInstruments: ReturnType<typeof bookingInstrumentsReducer>;
  reservations: ReturnType<typeof reservationsReducer>;
  users: ReturnType<typeof usersReducer>;
  bookingAdmin: ReturnType<typeof bookingAdminReducer>;
  actionTypes: ReturnType<typeof actionTypesReducer>;
};
