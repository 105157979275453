import { AppUserType } from "../../../Models/AppUserType";

const initialState: AppUserType = {
  id: "",
  email: "",
  lastFilter: "",
  roles: [],
  timezone: "",
};

export default initialState;
