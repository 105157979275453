import { AppUserType } from "../../../Models/AppUserType";
import { stringifyFilter } from "../../../utils/store/lastFilter";
import * as types from "./actionTypes";

export const loadUserInfo = (userInfo: AppUserType) => ({
  type: types.LOAD_USER_INFO,
  payload: userInfo,
});

export const updateFilter = (filterDetails: unknown) => ({
  type: types.UPDATE_FILTER,
  payload: stringifyFilter(filterDetails),
});
