import { toQueryParameter } from "@digitallab/grid-common-components";
import { OwcIconButton, OwcListItem, OwcMenu, OwcTooltip } from "@one/react";
import { isAfter, parseISO } from "date-fns";
import moment from "moment-timezone";
import { MouseEvent, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ENTRY_TYPE } from "../../constants";
import { RootStore } from "../../reducers";
import { BookingRewireDataType } from "../../Models/BookingRewireDataType";

const CoverMenuItem = styled.div`
  height: 0.973480224609375px;
  width: 100%;
  border-radius: 0;
  background: var(--one-color-gray-300);
`;

type BookingMenuActionParams = {
  params: {
    data: BookingRewireDataType;
  };
  handlers: {
    deleteEquipment: () => void;
    editBooking: (data: BookingRewireDataType) => void;
    setRowEquipmentId: React.Dispatch<React.SetStateAction<string>>;
    setPopupEnableStatus: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

const BookingMenuAction = ({
  params,
  handlers: { deleteEquipment, editBooking, setRowEquipmentId, setPopupEnableStatus },
}: BookingMenuActionParams) => {
  const user = useSelector((store: RootStore) => store.user);
  const [anchorEl, setAnchorEl] = useState<HTMLOwcIconButtonElement | null>(null);
  const currentTimeString = moment.tz(user.timezone).utc().format();

  const handleSettingClose = () => {
    setAnchorEl(null);
  };
  const handleSettingClick = (
    event: MouseEvent<HTMLOwcIconButtonElement, globalThis.MouseEvent>,
    params: { data: BookingRewireDataType }
  ) => {
    setRowEquipmentId?.(params?.data?.id);
    setAnchorEl(event.currentTarget);
  };

  const userDoesNotHaveRightsToWriteInLogbook =
    !user.roles.includes("LogbookAdmin") && !user.roles.includes("LogbookWriter");

  const getLogParamsList = (data: BookingRewireDataType) => {
    const { bookingEntries, description, type, elnId, dateTo } = data;
    const logLinks = [];
    const bookingType = type === "Run" ? "run" : "action";
    const standaloneEquipmentIdList = bookingEntries
      .filter(({ equipment }) => equipment.entryType === ENTRY_TYPE.standaloneEquipment)
      .map(({ inventoryId }) => inventoryId);
    const runStatus = isAfter(new Date(), parseISO(dateTo)) ? "COMPLETED" : "PROCESSING";

    type BaseFormParams = {
      description: string;
    };

    type RunFormParams = BaseFormParams & {
      runStatus: string;
      eLNid: string;
    };

    type ActionFormParams = BaseFormParams & {
      action: string;
    };

    type FormParams = RunFormParams | ActionFormParams;

    let formParams: FormParams;
    const baseParams: BaseFormParams = { description };
    if (bookingType === "run") {
      formParams = { ...baseParams, runStatus, eLNid: elnId };
    } else {
      formParams = { ...baseParams, action: type };
    }
    const clusterEquipmentIdList = bookingEntries
      .filter(({ equipment }) => equipment.entryType === ENTRY_TYPE.cluster)
      .map(({ inventoryId }) => inventoryId);
    if (clusterEquipmentIdList.length > 0) {
      clusterEquipmentIdList.forEach((element) => {
        logLinks.push(
          `/logs/cluster/${bookingType}${toQueryParameter({
            ...formParams,
            equipmentIdList: [element],
          })}`
        );
      });
    }
    if (standaloneEquipmentIdList.length > 0) {
      logLinks.push(
        `/logs/${bookingType}${toQueryParameter({ ...formParams, equipmentIdList: standaloneEquipmentIdList })}`
      );
    }
    return logLinks;
  };

  return (
    <>
      <OwcIconButton
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
        id={params?.data?.id}
        data-testid="booking-menu-toggle"
        disabled={!params?.data?.active}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
      {ReactDOM.createPortal(
        <OwcMenu
          data-testid="simple-menu"
          scrollPadding={false}
          anchor={params?.data?.id}
          visible={!!anchorEl}
          onClickOutside={handleSettingClose}
          disablePortal={true}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              setPopupEnableStatus(true);
              editBooking(params?.data);
            }}
            disabled={
              !params?.data?.active ||
              params?.data?.checkInDone ||
              (params?.data?.checkInRequired && params.data.checkInStartsAt <= new Date().getTime() / 1000) ||
              params?.data?.dateTo < currentTimeString
            }
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Edit
          </OwcListItem>
          <div id={`AddLogMenuOption-${params?.data?.id}`}>
            <OwcListItem
              data-testid="simple-menu-filter"
              onClick={() => {
                setAnchorEl(null);
                const logParamsList = getLogParamsList(params.data);
                logParamsList.forEach((params) => {
                  window.open(`${process.env.REACT_APP_LOGBOOK_APP_URL}${params}`, "_blank");
                });
              }}
              disabled={userDoesNotHaveRightsToWriteInLogbook}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Add log
            </OwcListItem>
            {userDoesNotHaveRightsToWriteInLogbook && (
              <OwcTooltip
                anchor={`AddLogMenuOption-${params?.data?.id}`}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                You do not have permissions to create log entry.
              </OwcTooltip>
            )}
          </div>
          <CoverMenuItem />
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              deleteEquipment();
            }}
            disabled={
              !params?.data?.active ||
              params?.data?.dateFrom < currentTimeString ||
              params?.data?.dateTo < currentTimeString
            }
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Cancel
          </OwcListItem>
        </OwcMenu>,
        document.body
      )}
    </>
  );
};
export default BookingMenuAction;
