import DLabGrid from "../../components/DLabGrid/DLabGrid";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BACKDROP_ZINDEX, MyBookingMeta, singleEventSkeleton } from "../../constants";
import {
  filterParams,
  getWeekStartAndEndInISOString,
  onlyUnique,
  replaceOffsetWithOther,
  valueOrEmpty,
} from "../../utils/helpers/text/index";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { getAllData } from "../../utils/helpers/fetching";
import { GET_BOOKING_DATA_BY_SITE, GET_BOOKING_ENTRIES_BY_BOOKING_ID } from "../../gql/bookingapi";
import { compose, withApollo } from "react-apollo";
import { connect, useSelector } from "react-redux";
import { loadPendingReservations as loadPendingReservationsAction } from "../booking-calendars/redux/actions";
import { OwcBackdrop, OwcBadge, OwcButton, OwcProgressSpinner, OwcTypography } from "@one/react";
import BookingMenuAction from "./BookingMenuAction";
import moment from "moment";
import BookingDetailRenderer from "../../components/shared/BookingDetailRenderer";
import { useHistory } from "react-router-dom";
import { BookingContext } from "../booking/context";
import { cloneDeep, uniqBy } from "lodash";
import CheckInButton from "../../components/shared/CheckInButton/CheckInButton";
import { EN_DASH } from "@digitallab/grid-common-components";
import { BookingTypeChip } from "../../components/shared/BookingTypeChip";

const BookingList = ({
  client,
  resetAll,
  searchQuery,
  setSearchQuery,
  setSelectedDateRange,
  setDisableResetAll,
  selectedDateRange,
  setIsDropBooking,
  setIsDialogOpen,
  showAdvanceFilter,
  selectedEquipment,
  setSelectedEquipment,
  setOpenMultiDeleteDialog,
  setRowEquipmentId,
  tempRowData,
  setTempRowData,
  setResetAll,
  loadPendingReservations,
  setPopupEnableStatus,
}) => {
  const { nextToken, data, nextData, fetching, dispatchAction } = useContext(PaginationContext);
  const { dispatchAction: bookingDispatchAction } = useContext(BookingContext);
  const user = useSelector((store) => store.user);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const rowStyle = { background: "var(--one-color-cobas-accent-gray-100)" };
  const history = useHistory();
  useEffect(() => {
    if (!fetching) {
      fetchData().then(() => setResetAll(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, nextData]);

  useEffect(() => {
    if (gridApi) {
      gridRef.current.api.setQuickFilter(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const dateFromFormatter = (params) => {
    return moment(params?.data?.dateFrom).tz(user.timezone).format("DD-MMM-YYYY");
  };
  const dateToFormatter = (params) => {
    return moment(params?.data?.dateTo).tz(user.timezone).format("DD-MMM-YYYY");
  };

  const statusGetter = (params) => {
    return params?.data?.active ? "Active" : "Cancelled";
  };
  const equipmentGetter = (params) => {
    const equipment = params?.data?.bookedEquipments ?? [];
    if (!equipment.length) {
      return "-";
    }
    const result = equipment[0] && equipment[0].length > 10 ? equipment[0].substring(0, 10) + "... " : equipment[0];
    const suffix = equipment.length - 1 > 0 ? `+${equipment.length - 1}` : "";
    return result + suffix;
  };
  const reservedForGetter = (params) => {
    const users = params?.data?.bookedUsers ?? [];
    if (!users.length) {
      return "-";
    }
    const name = users[0]?.givenName ?? null + users[0]?.familyName ?? null;
    const userName = users[0]?.name ?? null;
    if (!name && !userName) {
      return "-";
    }
    const combinedName = name + " (" + userName + ") ";
    const result = combinedName.length > 10 ? combinedName.substring(0, 10) + "... " : combinedName;
    const suffix = users.length - 1 > 0 ? ` +${users.length - 1}` : "";
    return result + suffix;
  };
  const reservedForFilterFormatterGetter = (params) => {
    const users = params?.data?.bookedUsers ?? [];
    if (!users.length) {
      return "-";
    }
    return params?.data?.bookedUsers.map((user) => {
      const name = user?.givenName ?? null + user?.familyName ?? null;
      const userName = user?.name ?? null;
      return name + " (" + userName + ") ";
    });
  };
  const projectGetter = (params) => {
    return valueOrEmpty(params?.data?.project, false, "-");
  };
  const descriptionGetter = (params) => {
    return valueOrEmpty(params?.data?.description, false, "-");
  };

  const fetchData = async () => {
    dispatchAction({
      type: "fetching",
      payload: true,
    });

    let dateFrom, dateTo;

    if (selectedDateRange[0] instanceof Date) {
      const dateFromString = moment(selectedDateRange[0]).format();
      const dateToString = moment(selectedDateRange[1]).format();
      const offset = moment.tz(user.timezone).format("Z");
      dateFrom = moment(replaceOffsetWithOther(dateFromString, offset)).utc().format();
      dateTo = moment(replaceOffsetWithOther(dateToString, offset, true)).utc().format();
    } else {
      dateFrom = moment(selectedDateRange[0]).utc().format();
      dateTo = moment(selectedDateRange[1]).utc().format();
    }

    const { items, nextToken: token } = await getAllData({
      client,
      query: GET_BOOKING_DATA_BY_SITE,
      dataPath: ["data", "getBookingDataBySite"],
      variables: {
        filter: JSON.stringify({
          and: [
            { active: { eq: true } },
            {
              and: [{ dateFrom: { le: dateTo } }, { dateTo: { ge: dateFrom } }],
            },
            { reservedForUsers: { contains: user.user } },
          ],
        }),
        dateFrom: dateTo,
        site: user.site,
        limit: 1000,
        nextToken,
      },
    });

    dispatchAction({
      type: "nextToken",
      payload: {
        token,
        data: items,
      },
    });
  };

  const actionRender = (params) => {
    return (
      <BookingMenuAction
        params={params}
        handlers={{ deleteEquipment, editBooking, setRowEquipmentId, setPopupEnableStatus }}
      />
    );
  };

  const overrideCellRender = (key, defaultObj) => {
    switch (key) {
      case "type":
        defaultObj = {
          ...defaultObj,
          filter: false,
          cellRenderer: (item) =>
            item?.data?.type ? <BookingTypeChip key={item.data.id} item={item.data} /> : EN_DASH,
        };
        break;
      case "checkIn":
        defaultObj = {
          ...defaultObj,
          cellRenderer: (item) =>
            item?.data?.checkInRequired ? <CheckInButton key={item.data.id} item={item.data} /> : null,
        };
        break;
      case "dateFrom":
        defaultObj = {
          ...defaultObj,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: dateFromFormatter,
          filterValueGetter: dateFromFormatter,
        };
        break;
      case "dateTo":
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: dateToFormatter,
          filterValueGetter: dateToFormatter,
        };
        break;
      case "equipment":
        defaultObj = {
          ...defaultObj,
          valueFormatter: equipmentGetter,
          filterValueGetter: (params) => params.data.bookedEquipments,
        };
        break;
      case "active":
        defaultObj = {
          ...defaultObj,
          valueGetter: statusGetter,
          filterValueGetter: statusGetter,
        };
        break;

      case "reservedForUsers":
        defaultObj = {
          ...defaultObj,
          valueGetter: reservedForGetter,
          filterValueGetter: reservedForFilterFormatterGetter,
        };
        break;
      case "project":
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            defaultToNothingSelected: true,
            buttons: ["reset", "apply"],
          },
          valueGetter: projectGetter,
          filterValueGetter: projectGetter,
        };
        break;
      case "description":
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            defaultToNothingSelected: true,
            buttons: ["reset", "apply"],
          },
          valueGetter: descriptionGetter,
          filterValueGetter: descriptionGetter,
        };
        break;
      case "actionButton":
        defaultObj = {
          ...defaultObj,
          width: 0,
          maxWidth: 50,
          field: "actions",
          headerName: "Actions",
          filter: false,
          pinned: "right",
          lockVisible: true,
          lockPosition: "right",
          cellClass: "action-render",
          cellRenderer: actionRender,
        };

        break;

      case "detailExpander":
        defaultObj = {
          ...defaultObj,
          cellRenderer: "agGroupCellRenderer",
          pinned: "left",
        };

        break;

      default:
        defaultObj = {
          ...defaultObj,
          valueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-"),
          filterValueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false),
        };
        break;
    }
    return defaultObj;
  };

  const parentColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in MyBookingMeta.fields) {
      defaultObj = {
        field: key,
        width: MyBookingMeta.fields[key].width,
        maxWidth: MyBookingMeta.fields[key].maxWidth,
        minWidth: MyBookingMeta.fields[key].minWidth,
        headerName: MyBookingMeta.fields[key].text,
        filter: true,
        floatingFilter: showAdvanceFilter,
      };
      defaultObj = overrideCellRender(key, defaultObj);

      colDef.push(defaultObj);
    }
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MyBookingMeta.fields, showAdvanceFilter]);

  const onGridReady = (params) => {
    setGridApi(params);
  };
  let attr = {
    height: 400,
    columnDefs: parentColumnDef,
    animateRows: true,
    rowExport: false,
    defaultToolPanel: "filters",
    suppressContextMenu: true,
    hiddenByDefault: true,
    rowData: tempRowData,
    onGridReady,
    suppressRowClickSelection: true,
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      sortable: true,
    };
  }, []);

  useEffect(() => {
    if (gridApi && resetAll) {
      gridRef.current.api.setFilterModel();
      setSearchQuery("");
      setSelectedEquipment([]);
      setSelectedDateRange([
        getWeekStartAndEndInISOString("start", user.timezone),
        getWeekStartAndEndInISOString("end", user.timezone),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll]);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      const node = params.level;
      const isGroupRow = node === 0;
      const isActive =
        params.data.active === true &&
        moment(new Date(params?.data?.dateFrom)).tz(user.timezone).format("YYYY-MM-DD HH:mm") >
          moment(new Date()).tz(user.timezone).format("YYYY-MM-DD HH:mm");
      if (isGroupRow) {
        params.selectable = isActive;
      }
      return isGroupRow && isActive;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempArray = [];
    if (tempRowData.length > 0) {
      tempArray = [...tempArray, ...tempRowData];
    }
    if (data.length > 0) {
      tempArray = [...tempArray, ...data];
    }
    const uniqData = uniqBy(tempArray, "id");
    setTempRowData(uniqData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const scrollEvent = () => {
    const container = document.querySelector(".grid-container");
    if (Math.ceil(container.clientHeight + container.scrollTop) >= container.scrollHeight && nextToken) {
      dispatchAction({ type: "nextData" });
    }
  };

  const deleteEquipment = () => {
    setIsDialogOpen(true);
    setIsDropBooking(true);
  };

  const editBooking = async (editData) => {
    const data = cloneDeep(editData);
    const currentTimeString = moment.tz(user.timezone).utc().format();
    dispatchAction({
      type: "fetching",
      payload: true,
    });
    //fetch data from grpahql
    const { items } = await getAllData({
      client,
      query: GET_BOOKING_ENTRIES_BY_BOOKING_ID,
      drillData: true,
      fetchPolicy: "no-cache",
      variables: {
        bookingId: data.id,
        nextToken: null,
        filter: { active: { eq: true } },
      },
      dataPath: ["data", "bookingEntriesByBookingId"],
    });

    if (items.length > 0) {
      const keyArray = Object.keys(singleEventSkeleton);
      data["bookingEntries"] = items.map((item) => {
        let tempObj = {};
        keyArray.forEach((key) => {
          tempObj = {
            ...tempObj,
            [key]: item[key] ?? null,
          };
        });
        //extra keys
        tempObj = {
          ...tempObj,
          inventoryId: item.resource,
          equipment: item.equipment,
          title: item.type,
          description: item?.description,
          elnId: item?.elnId,
          project: item?.project,
          createdBy: item?.createdBy,
          reservedForEmails: item?.reservedForEmails,
        };
        return tempObj;
      });
      if (items[0].elnId) data.elnId = items[0].elnId;
    }
    bookingDispatchAction({
      type: "editBookingData",
      payload: {
        isEditMode: true,
        editBookingData: data,
        isOngoing: currentTimeString > data.dateFrom && currentTimeString < data.dateTo,
      },
    });

    const tempBookingEntries = data?.bookingEntries.map((item) => {
      return {
        ...item,
        inventoryId: item.resource,
        isEditEventFlag: true,
        isOngoingEventFlag:
          (currentTimeString > item.end && currentTimeString > item.start) ||
          (currentTimeString > item.start && currentTimeString < item.end),
        editable: currentTimeString < item.end,
        title: item?.type,
      };
    });

    loadPendingReservations(tempBookingEntries);
    if (data) history.push("/");
  };

  const onRowSelected = useCallback(
    (params) => {
      if (params?.source === "checkboxSelected" || params?.source === "rowClicked") {
        const id = params?.data?.id;
        if (!selectedEquipment.includes(id)) {
          //selected scenario
          setSelectedEquipment((prevState) => {
            return onlyUnique([...prevState, id]);
          });
        } else {
          // un-selected
          let uniqueList = onlyUnique(selectedEquipment);
          const index = uniqueList.indexOf(id);
          if (index > -1) {
            let temp = uniqueList;
            temp.splice(index, 1);
            setSelectedEquipment(() => {
              return temp;
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEquipment]
  );
  return (
    <>
      {fetching && (
        <OwcBackdrop opacity={0.3} style={{ zIndex: BACKDROP_ZINDEX }}>
          <OwcProgressSpinner />
        </OwcBackdrop>
      )}

      <div
        className="grid-container"
        onScroll={scrollEvent}
        style={{
          width: "100%",
          height: "415px",
          overflow: "scroll",
        }}
      >
        <DLabGrid
          {...attr}
          pagination={false}
          suppressPaginationPanel={false}
          defaultColDef={defaultColDef}
          masterDetail={true}
          rowSelection={"multiple"}
          detailCellRenderer={BookingDetailRenderer}
          detailRowAutoHeight={true}
          autoGroupColumnDef={"autoGroupColumnDef"}
          onRowSelected={onRowSelected}
          isRowSelectable={isRowSelectable}
          gridRef={gridRef}
          rowStyle={rowStyle}
          domLayout="autoHeight"
          onFilterChanged={(params) => {
            const filters = params.api.getFilterModel();
            if (Object.keys(filters).length > 0) {
              setDisableResetAll(false);
            }
          }}
        />
      </div>
      <span name="pagination" style={{ display: "flex", alignItems: "center" }}>
        <OwcTypography variant="body2" style={{ marginLeft: "auto", marginRight: "10px" }}>
          Components added
        </OwcTypography>
        <OwcBadge
          type="active"
          style={{
            marginRight: "20px",
            width: "24px",
            height: "24px",
          }}
        >
          {selectedEquipment?.length}
        </OwcBadge>
      </span>

      <OwcButton
        style={{ marginLeft: "auto", marginRight: "20px", marginTop: "15px" }}
        variant="primary"
        onClick={() => {
          setOpenMultiDeleteDialog(true);
        }}
        disabled={selectedEquipment.length === 0}
      >
        Cancel booking
      </OwcButton>
    </>
  );
};

export default compose(
  connect(null, {
    loadPendingReservations: loadPendingReservationsAction,
  }),
  withApollo
)(BookingList);
