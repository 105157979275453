import * as types from "./actionTypes";
import initialState from "./initialState";

export default function reducer(
  state = initialState,
  { type, payload }: { type: string; payload: { [key: string]: unknown } }
) {
  switch (type) {
    case types.LOAD_USER_INFO:
      return { ...state, ...payload };
    case types.UPDATE_FILTER:
      return { ...state, lastFilter: payload };
    default:
      return state;
  }
}
